import { getCSRFCookieValue } from '@/utils/common';
import { config } from '@/config';

export const CSRFTokenField = () => {
  const { csrfEnabled, csrfTokenName, csrfCookieName } = config;
  const csrfToken = getCSRFCookieValue(csrfCookieName);

  if (csrfEnabled) {
    return <input type={'hidden'} name={csrfTokenName} value={csrfToken} />;
  }

  return null;
};
